/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it


export const onRouteUpdate = () => {
    //console.log("update");
}

export const onClientEntry = () => {
    sessionStorage.setItem("firstEntry", "true");
  }