module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-6EDPPM4T5Y","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false,"pageTransitionDelay":300,"respectDNT":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.fiberswitch.be","noQueryString":true,"noHash":true,"noTrailingSlash":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"fiberswitch website","short_name":"fiberswitch","start_url":"/","background_color":"#dbe6f8","theme_color":"#dbe6f8","display":"minimal-ui","icon":"src/images/fs-favicon-white-dots-bg2.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e2bb27f1dc09165276ffffdcd4425477"},
    },{
      plugin: require('../node_modules/gatsby-remark-images-datocms/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"2140641d6ec55e4f9cc385d7493aec","maxWidth":800},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
